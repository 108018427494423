import { configureStore } from '@reduxjs/toolkit';

import categorySlice from './categorySlice';
import mediaSlice from './mediaSlice';
import productSlice from './productSlice';
import attributeSlice from './attributeSlice';
import deliverySlice from './deliverySlice';
import orderSlice from './orderSlice';
import preOrderSlice from './preOrderSlice';
import homeContentSlice from './homeContentSlice';
import rewardSlice from './rewardSlice';
import offerSlice from './offerSlice';
import menuSlice from './menuSlice';
import pageSlice from './pageSlice';
import settingSlice from './settingSlice';
import searchSlice from './searchSlice';
import authSlice from './authSlice';
import specSlice from './specSlice';
import statisticsSlice from './statisticsSlice';
import emiSlice from './emiSlice';
import staffSlice from './staffSlice';
import customerSlice from './customerSlice';
import reviewSlice from './reviewSlice';

const store = configureStore({
    reducer: {
        category: categorySlice,
        media: mediaSlice,
        product: productSlice,
        attribute: attributeSlice,
        delivery: deliverySlice,
        order: orderSlice,
        preOrder: preOrderSlice,
        homeContent: homeContentSlice,
        reward: rewardSlice,
        offer: offerSlice,
        menu: menuSlice,
        page: pageSlice,
        setting: settingSlice,
        search: searchSlice,
        auth: authSlice,
        spec: specSlice,
        statistics: statisticsSlice,
        emi: emiSlice,
        staff: staffSlice,
        customer: customerSlice,
        Reviews: reviewSlice,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;