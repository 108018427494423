import { getToken } from './token';
import { baseApiUrl } from './../constants/variables';

export const Post = async ({ path, data, useAuth }: { path: string, data: any, useAuth?: boolean }) => {
    const token = getToken();
    try {
        if (useAuth && !token) {
            throw new Error("Unauthorized user");
        }
        return fetch(`${baseApiUrl}${path}`, {
            method: 'POST',
            headers: {
                "Content-Type": "applcation/json",
                'Authorization': useAuth ? `Bearer ${token}` : ''
            },
            body: JSON.stringify(data)
        });
    } catch (error) {
        throw error;
    }
};
export const Get = async ({ path, useAuth }: { path: string, useAuth?: boolean }) => {
    const token = getToken();
    try {
        if (useAuth && !token) {
            throw new Error("Unauthorized user");
        }
        return fetch(`${baseApiUrl}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': useAuth ? `Bearer ${token}` : ''
            }
        });
    } catch (error) {
        throw error;
    }
};
export const Put = async ({ path, data, useAuth }: { path: string, data: any, useAuth?: boolean }) => {
    const token = getToken();
    try {
        if (useAuth && !token) {
            throw new Error("Unauthorized user");
        }
        return fetch(`${baseApiUrl}${path}`, {
            method: 'PUT',
            headers: {
                 "Content-Type": "applcation/json",
                'Authorization': useAuth ? `Bearer ${token}` : ''
            },
            body: JSON.stringify(data)
        });
    } catch (error) {
        throw error;
    }
};
export const Delete = async ({ path, useAuth }: { path: string, useAuth?: boolean }) => {
    const token = getToken();
    try {
        if (useAuth && !token) {
            throw new Error("Unauthorized user");
        }
        return fetch(`${baseApiUrl}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': useAuth ? `Bearer ${token}` : ''
            }
        });
    } catch (error) {
        throw error;
    }
};


const Http = {
    Post,
    Get,
    Put,
    Delete
}

export default Http;