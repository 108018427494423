import { createSlice } from '@reduxjs/toolkit';
import { type SiteSetting, type ShopAddress } from './../models/setting';
import Http from './../helpers/http';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';

type SettingState = {
    settingList: {
        loading: boolean,
        data: SiteSetting[],
        error?: string
    },
    storeAddressList: {
        loading: boolean,
        data: ShopAddress[],
        error?: string
    }
};
const initialState: SettingState = {
    settingList: {
        loading: false,
        data: []
    },
    storeAddressList: {
        loading: false,
        data: []
    }
};

const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        fetchSettingsRequest: state => {
            state.settingList.loading = true;
        },
        fetchSettingsSuccess: (state, action) => {
            state.settingList = {
                loading: false,
                data: action.payload
            };
        },
        fetchSettingsFailure: (state, action) => {
            state.settingList.loading = false;
            state.settingList.error = action.payload;
        },
        //shop address->start
        fetchShopAddressListRequest: (state) => {
            state.storeAddressList.loading = true;
        },
        fetchShopAddressListSuccess: (state, action) => {
            state.storeAddressList = {
                loading: false,
                data: action.payload
            }
        },
        fetchShopAddressListFailure: (state, action) => {
            state.storeAddressList.loading = true;
            state.storeAddressList.error = action.payload;
        },
        addNewAddress: (state, action) => {
            state.storeAddressList.data.push(action.payload);
        },
        updateAddress: (state, action) => {
            state.storeAddressList.data = state.storeAddressList.data.map(address => address.id === action.payload.id ? action.payload : address);
        },
        deleteAddress: (state, action) => {
            state.storeAddressList.data = state.storeAddressList.data.filter(address => address.id !== action.payload)
        }
        //shop address->end
    }
});

export default settingSlice.reducer;
export const settingActions = settingSlice.actions;

//OTHER ACTIONS
export const fetchSettings = () => async (dispatch: any) => {
    dispatch(settingActions.fetchSettingsRequest());
    try {
        const res = await Http.Get({
            path: `settings`,
            useAuth: true,
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("failed");
        }
        dispatch(settingActions.fetchSettingsSuccess(result));

    } catch (error: any) {
        dispatch(settingActions.fetchSettingsFailure(error.message));
    }
};

export const updateSettingAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `settings`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(settingActions.fetchSettingsSuccess(result));
        toast.success("Updated");

    } catch (error: any) {
        toast.error(error.message);
    }
};

//address
export const fetchAddressListAction = () => async (dispatch: any) => {
    dispatch(settingActions.fetchShopAddressListRequest());
    try {
        const res = await Http.Get({
            path: `address`,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("failed");
        }
        dispatch(settingActions.fetchShopAddressListSuccess(result.data));

    } catch (error: any) {
        dispatch(settingActions.fetchShopAddressListFailure(error.message));
    }
};

export const addNewAddressAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({
            path: `address`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("failed");
        }
        dispatch(settingActions.addNewAddress(result));


    } catch (error: any) {
        throw error;
    }
}

export const updateAddressAction = (id: number, data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `address/${id}`,
            data,
            useAuth: true
        });
        const result = await res.json();

        if (!res.ok) {
            throw new Error("failed");
        }
        dispatch(settingActions.updateAddress(result));

    } catch (error: any) {
        throw error;
    }
}

export const deleteAddressAction = (id: number) => async () => {
    try {
        const res = await Http.Delete({
            path: `address/${id}`,
            useAuth: true
        });
        // const result = await res.json();

        if (!res.ok) {
            throw new Error("failed");
        }

    } catch (error: any) {
        throw error;
    }
}