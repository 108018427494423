export const baseApiUrl = `https://snapadmin.themedepo.com/api/`;

const baseMediaPath = `https://snapadmin.themedepo.com/`;
export const preOrderImagePath = `${baseMediaPath}storage/media/preorder/`;
export const mediaPath = {
  large: `${baseMediaPath}storage/media/large/`,
  medium: `${baseMediaPath}storage/media/medium/`,
  thumb: `${baseMediaPath}storage/media/thumb/`,
};

export const packageMedia =
  "https://snap.themedepo.com/storage/media/packaging/";

export const tinyMceKey = `fldvg4rlreldvgcx1tgzdmh3osr49orf7nxshnzbfm35gbvd`;
export const shop = {
  shop_url: "https://snaptest.themedepo.com/",
};
