import { createSlice } from "@reduxjs/toolkit";
import { type Order } from './../models/order';
import type Pagination from './../models/pagination';
import { type OrderStatus } from './../models/order';
import { toast } from "react-toastify";
import Http from './../helpers/http';

type OrderState = {
    orderList: {
        loading: boolean,
        data: Array<Order>,
        error?: string | null,
        meta?: Pagination,
        isSearchResult?: boolean
    },
    orderStatusList: {
        id: OrderStatus,
        title: string
    }[]
};

const initialState: OrderState = {
    orderList: {
        loading: false,
        data: []
    },
    orderStatusList: [
        {
            id: "payment-pending",
            title: "Payment Pending"
        },
        {
            id: "payment-received",
            title: "Payment Received"
        },
        {
            id: "cancelled",
            title: "Cancelled"
        }
    ]
}

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        fetchOrderListRequest: (state) => {
            state.orderList.loading = true;
        },
        fetchOrderListSuccess: (state, action) => {
            state.orderList = {
                loading: false,
                data: action.payload.data,
                error: null,
                meta: action.payload.meta,
                isSearchResult: action.payload.isSearchResult
            }
        },
        fetchOrderListFailure: (state, action) => {
            state.orderList.loading = false;
            state.orderList.error = action.payload;
        },
        //UPDATE ORDER STATUS
        updateOrderStatusSuccess: (state, action) => {
            state.orderList.data = state.orderList.data.map(order => {
                if (order.id === action.payload.id) {
                    return {
                        ...order,
                        status: action.payload.status
                    }
                } else {
                    return { ...order };
                }
            });

        }
    }
});

export default orderSlice.reducer;
export const orderActions = orderSlice.actions;

//ACTIONS
export const fetchOrderListAction = (pageNo: number) => async (dispatch: any) => {
    dispatch(orderActions.fetchOrderListRequest());
    try {
        const response = await Http.Get({ path: `orders?page=${pageNo}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Product fetching failed");
        }
        dispatch(orderActions.fetchOrderListSuccess({
            data: result.data,
            meta: result.meta,
            isSearchResult: false
        }));

    } catch (error: any) {
        dispatch(orderActions.fetchOrderListFailure(error.message));
    }
}

export const updateOrderStatusAction = (data: { status: OrderStatus }, orderId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `orders/${orderId}`, data, useAuth: true });

        if (!response.ok) {
            throw new Error("Update failed");
        }

        // const result = await response.json();
        dispatch(orderActions.updateOrderStatusSuccess({ id: orderId, status: data.status }));
        toast.success("Status updated");

    } catch (error: any) {
        toast.error(error.message);
    }
};

export const createNewOrderAction = (data: any) => async (dispatch: any) => {
    const toastId = toast.loading("Creating new order...");
    try {
        const response = await Http.Post({ path: `orders`, data, useAuth: true });
        // const result = await response.json();
        if (!response.ok) {
            throw new Error("Fail to create order");
        }
        toast.update(toastId, { render: "Order Created", type: "success", isLoading: false, autoClose: 4000 });
        dispatch(fetchOrderListAction(1));

    } catch (error: any) {
        toast.update(toastId, { render: error.message, type: "error", isLoading: false, autoClose: 4000 });
        throw error;
    }
};

export const searchOrderAction = (data: any, page?: number) => async (dispatch: any) => {
    dispatch(orderActions.fetchOrderListRequest());
    try {
        const res = await Http.Post({
            path: `search/orders${page ? `?page=${page}` : ''}`,
            data: {
                ...data,
                paginate: 1
            },
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Product fetching failed");
        }
        dispatch(orderActions.fetchOrderListSuccess({
            data: result.data,
            meta: result.meta,
            isSearchResult: true
        }));

    } catch (error: any) {
        dispatch(orderActions.fetchOrderListFailure(error.message));
    }
};