import { createSlice } from "@reduxjs/toolkit";
import Http from "../helpers/http";
import { toast } from "react-toastify";
//import errorExtractor from '../utils/errorExtractor';
import { Simplereview, type ReviewType } from "../models/reviews";

interface ReviewState {
  review: {
    loading: boolean;
    data: ReviewType[];
    error: string | null;
  };
}

// Define the initial state using that type
const initialState: ReviewState = {
  review: {
    loading: false,
    data: [],
    error: null,
  },
};

export const reviewSlice = createSlice({
  name: "Review",
  initialState,
  reducers: {
    revwFetchLoading: (state) => {
      state.review.loading = true;
    },
    revwFetchSuccess: (state, action) => {
      state.review.loading = false;
      state.review.data = action.payload;
    },
    revwFetchFailure: (state, action) => {
      state.review.loading = false;
      state.review.error = action.payload;
    },
    //UPDATE review
    updatereviewSuccess: (state, action) => {
      const catIndex = state.review.data.findIndex(
        (cat) => cat.id === Number(action.payload.id)
      );
      state.review.data[catIndex] = action.payload;
    },
  },
});

export const reviewActions = reviewSlice.actions;

export default reviewSlice.reducer;

//other actions
export const fetchReviewAction = () => {
  return async (dispatch: any) => {
    dispatch(reviewActions.revwFetchLoading());

    try {
      const response = await Http.Get({ path: `reviews`, useAuth: true });
      if (!response.ok) {
        throw new Error("Fetching failed");
      }
      const data = await response.json();
      dispatch(reviewActions.revwFetchSuccess(data.data));
    } catch (error: any) {
      dispatch(reviewActions.revwFetchFailure(error.message));
    }
  };
};

//DELETE review
export const deleteReviewAction =
  (reviewId: number) => async (dispatch: any) => {
    const toastId = toast.loading("Deleting review...");
    try {
      const response = await Http.Delete({
        path: `reviews/${reviewId}`,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error("Invalid review id");
      }
      toast.update(toastId, {
        render: "Deleted",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
      dispatch(fetchReviewAction());
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    }
  };

//UPDATE review
export const updateReviewAction = (data: Simplereview) => async (dispatch: any) => {
    const dataValue = {
      status :data.status,
      review: data.review,
      rating: data.rating.toFixed(1),
    };
    const toastId = toast.loading("Updating review...");
    try {
      const response = await Http.Put({
        path: `reviews/${data.id}`,
        data: dataValue,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error("review update failed");
      }

      const result = await response.json();
      delete result.children;

     // dispatch(reviewActions.updatereviewSuccess(result));
      toast.update(toastId, {
        render: "review updated",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
    } catch (error: any) {
      toast.update(toastId, {
        render: error.message,
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    }
  };
