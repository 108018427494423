import React from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
    icon?: React.ReactNode,
    to: string,
    children: any
}

const NavMenu = (props: Props) => {
    let resolved = useResolvedPath(props.to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <MenuItem
            icon={props.icon}
            // suffix={<span className="badge red">{'new'}</span>}
            className={clsx(match && 'bg-accent bg-opacity-10')}
        >
            <Link to={props.to} className={clsx(match && '!text-accent')}>{props.children}</Link>
        </MenuItem>
    )
}

export default NavMenu