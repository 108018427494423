import { BounceLoader, HashLoader } from "react-spinners";
import BarLoader from "react-spinners/BarLoader";

const Loader = () => {
    return (
        <div className='w-full h-[90vh] center-inner'>
            {/* <BarLoader color={"#d71f84"} loading={true} /> */}
            {/* <BounceLoader color="#d71f84" loading={true} /> */}
            <HashLoader color="#d71f84" loading={true}  />
        </div>
    )
}

export default Loader