import { createSlice } from '@reduxjs/toolkit';
import Http from './../helpers/http';
import { toast } from 'react-toastify';
import errorExtractor from '../utils/errorExtractor';
import { type Media } from '../models/media';

// Define a type for the slice state
export type Category = {
    id: number,
    collection_group_id: number,
    _lft: number,
    _rgt: number,
    parent_id: number | null,
    type: "static",
    attribute_data: {
        name: {
            en: string
        },
        title?: {
            en: string
        }
        description: {
            en: string
        }
    },
    sort: "custom",
    created_at: string,
    updated_at: string,
    deleted_at: string | null,
    urls: {
        id: string,
        language_id: number,
        element_type: string,
        element_id: number,
        slug: string,
        default: boolean,
        created_at: string,
        updated_at: string
    }[],
    logo_id: Media,
    header_id: Media,
    children?: any[]
}

type SimpleCategory = {
    name: string,
    slug: string,
    description?: string | null,
    collection_id?: number //if child category
};
interface CategoryState {
    categoryList: {
        loading: boolean,
        data: Category[],
        error: string | null
    },
    addCategory: {
        loading: boolean,
        error?: any
    }
}

// Define the initial state using that type
const initialState: CategoryState = {
    categoryList: {
        loading: false,
        data: [],
        error: null
    },
    addCategory: {
        loading: false
    }
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        catgFetchLoading: (state) => {
            state.categoryList.loading = true;
        },
        catgFetchSuccess: (state, action) => {
            state.categoryList.loading = false;
            state.categoryList.data = action.payload;
        },
        catgFetchFailure: (state, action) => {
            state.categoryList.loading = false;
            state.categoryList.error = action.payload;
        },
        //ADD CATEGORY
        addCategoryLoading: (state) => {
            state.addCategory.loading = true;
        },
        addCategorySuccess: (state) => {
            state.addCategory.loading = false;
            state.addCategory.error = null;
        },
        addCategoryFailure: (state, action) => {
            state.addCategory.loading = false;
            state.addCategory.error = action.payload;
        },
        //UPDATE CATEGORY
        updateCategorySuccess: (state, action) => {
            const catIndex = state.categoryList.data.findIndex(cat => cat.id === Number(action.payload.id));
            state.categoryList.data[catIndex] = action.payload;
        }
    },
})


export const categoryActions = categorySlice.actions;


export default categorySlice.reducer;

//other actions
export const fetchCategoriesAction = () => {
    return async (dispatch: any) => {
        dispatch(categoryActions.catgFetchLoading());

        try {
            const response = await Http.Get({ path: `collections`, useAuth: true });
            if (!response.ok) {
                throw new Error("Fetching failed");
            }
            const data = await response.json();
            dispatch(categoryActions.catgFetchSuccess(data.data));
        } catch (error: any) {
            dispatch(categoryActions.catgFetchFailure(error.message));
        }
    }
}

//ADD NEW CATEGORY
export const addCategoryAction = (data: SimpleCategory) => async (dispatch: any) => {
    dispatch(categoryActions.addCategoryLoading());
    try {
        const response = await Http.Post({ path: `collections${data.collection_id ? '/child' : ''}`, data, useAuth: true });
        const result = await response.json();

        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        dispatch(categoryActions.addCategorySuccess());
        dispatch(fetchCategoriesAction());

    } catch (error: any) {
        dispatch(categoryActions.addCategoryFailure({ msg: error.message }));
        toast.error(error.message);
        throw error;
    }
}

//DELETE CATEGORY
export const deleteCategoryAction = (catId: number) => async (dispatch: any) => {
    const toastId = toast.loading("Delete category...");
    try {
        const response = await Http.Delete({ path: `collections/${catId}`, useAuth: true });
        if (!response.ok) {
            throw new Error("Invalid category id");
        }
        toast.update(toastId, { render: "Deleted", type: "success", isLoading: false, autoClose: 4000 });
        dispatch(fetchCategoriesAction());

    } catch (error: any) {
        toast.update(toastId, { render: error.message, type: "error", isLoading: false, autoClose: 4000 });
    }
}

//UPDATE CATEGORY
export const updateCategoryAction = (categoryId: number, data: SimpleCategory) => async (dispatch: any) => {
    const toastId = toast.loading("Updating category...");
    console.log(data);
    try {
        const response = await Http.Put({ path: `collections/${categoryId}`, data, useAuth: true });
        if (!response.ok) {
            throw new Error("Category update failed");
        }

        const result = await response.json();
        delete result.children;

        dispatch(categoryActions.updateCategorySuccess(result));
        toast.update(toastId, { render: "Category updated", type: "success", isLoading: false, autoClose: 4000 });
    } catch (error: any) {
        toast.update(toastId, { render: error.message, type: "error", isLoading: false, autoClose: 4000 });
    }
}