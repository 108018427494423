import { useState, Suspense, useEffect, useMemo } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
    ProSidebar,
    Menu,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaImages } from 'react-icons/fa';
import { BsUiChecksGrid } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineShop, AiOutlineMenu, AiOutlineFileSearch } from 'react-icons/ai';
import { RiHome8Line, RiPagesLine, RiAdminLine } from 'react-icons/ri';
import { CgMenuMotion } from 'react-icons/cg';
import { GrServers } from 'react-icons/gr';
import { MdPeopleAlt } from 'react-icons/md';
import { MdOutlineLocalShipping, MdSettings, MdOutlineCampaign } from 'react-icons/md';
import { HiOutlineUsers } from 'react-icons/hi';
// import { GoReport } from 'react-icons/go';
import NavMenu from './NavMenu';
import clsx from 'clsx';
import { Dropdown } from 'antd';
import { useAppDispatch, useAppSelector } from './../hooks/redux';
import { authMeAction, logoutAction } from './../store/authSlice';

import { logoImg, logoIcon } from './../constants/images';
import Loader from './Loader';

type Props = {}

const Layout = (props: Props) => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const [collapsedSidebar, setCollapsedSidebar] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const me = useAppSelector(state => state.auth.me);

    useEffect(() => {
        dispatch(authMeAction()).finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!loading && !me.data) {
            navigation('/login');
        }
        // eslint-disable-next-line
    }, [loading, me.data])

    const onLogout = () => {
        dispatch(logoutAction());
    }


    function profileMenu() {
        return <div className='bg-white rounded flex flex-col gap-1 drop-shadow overflow-hidden'>
            <button className='px-2 py-1 bg-primary bg-opacity-0 hover:bg-opacity-20 w-36'>Profile</button>
            <button className='px-2 py-1 bg-primary-dark bg-opacity-0 hover:bg-opacity-20' onClick={onLogout}>Logout</button>
        </div>
    };

    const permissions = useMemo(() => {
        if (me.data && me.data.permissions.length) {
            return me.data.permissions[0].permissions;
        } else {
            return []
        }
    }, [me.data])

    if (loading) {
        return <Loader />
    }

    return (
        <div className='h-screen flex'>
            <ProSidebar
                breakPoint="md"
                collapsed={collapsedSidebar}
                toggled={toggleSidebar}
                onToggle={toggle => setToggleSidebar(toggle)}
            >
                <SidebarHeader className='py-2 center-inner bg-white'>
                    <Link to={"/"} className='w-[80%]'>
                        <img src={collapsedSidebar ? logoIcon : logoImg} alt="snap-cosmetics" className='w-full' />
                    </Link>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="circle">
                        {
                            permissions.includes("dashboard") &&
                            <NavMenu to='/' icon={<FaTachometerAlt />} >
                                Dashboard
                            </NavMenu>
                        }

                        {/* order manage */}
                        {
                            permissions.includes("order_manage") &&
                            <SubMenu
                                title={"Order Manage"}
                                icon={<BsUiChecksGrid />}
                            >
                                {/* <NavMenu to='/order/new'>
                                    Add New Order
                                </NavMenu> */}
                                <NavMenu to='/order'>
                                    Order List
                                </NavMenu>
                                {/* <NavMenu to='/order/pre'>
                                    Pre Orders
                                </NavMenu> */}
                            </SubMenu>
                        }
                        {/* /order manage */}

                        {/* product manage */}
                        {
                            permissions.includes("product_manage") &&
                            <SubMenu
                                title={"Product Manage"}
                                icon={<AiOutlineShop />}
                            >
                                <NavMenu to='/product/new'>
                                    Add New
                                </NavMenu>
                                <NavMenu to='/product'>
                                    All Product
                                </NavMenu>
                                <NavMenu to='/category'>
                                    Categories
                                </NavMenu>
                                {/* <NavMenu to='/attributes'>
                                    Attributes
                                </NavMenu> */}
                            </SubMenu>
                        }
                        {/* /product manage */}
                        {/* Reviws manage */}
                        <SubMenu title={"Review manage"} icon={<AiOutlineShop />}>
                            <NavMenu to='/reviews'>
                                Review List
                            </NavMenu>
                            <NavMenu to='/reviews/pending-reviews'>
                                Pending Reviews
                            </NavMenu>
                        </SubMenu>

                        {/* customer manage */}
                        {
                            permissions.includes("customer") &&
                            <NavMenu to='/customer' icon={<HiOutlineUsers />}>
                                Customer Manage
                            </NavMenu>
                        }
                        {/* /customer manage */}

                        {
                            // permissions.includes("search_terms") &&
                            // <NavMenu to='/terms' icon={<AiOutlineFileSearch />} >
                            //     Search Terms
                            // </NavMenu>
                        }

                        {/* brand manage */}
                        {/* {
                            permissions.includes("brand_manage") &&
                            <SubMenu
                                title={"Brand Manage"}
                                icon={<RiShoppingBag3Line />}
                            >
                                <NavMenu to='/brand/new'>
                                    Add New
                                </NavMenu>
                                <NavMenu to='/brand'>
                                    All Brands
                                </NavMenu>
                            </SubMenu>
                        } */}
                        {/* /brand manage */}

                        {/* menu manage */}
                        {
                            permissions.includes("menu_manage") &&
                            <NavMenu to='/menu' icon={<CgMenuMotion />} >
                                Menu Manage
                            </NavMenu>
                        }
                        {/* /menu manage */}

                        {/* custom page manage */}
                        {
                            permissions.includes("custom_page") &&
                            <NavMenu to='/custom-page' icon={<RiPagesLine />} >
                                Custom Page
                            </NavMenu>
                        }
                        {/* /custom page manage */}

                        {/* delivery manage */}
                        {
                            // permissions.includes("delivery_manage") &&
                            // <SubMenu
                            //     title={"Delivery Manage"}
                            //     icon={<MdOutlineLocalShipping />}
                            // >
                            //     <NavMenu to='/delivery/type'>
                            //         Delivery Type
                            //     </NavMenu>
                            //     {/* <NavMenu to='/delivery/option'>
                            //     Delivery Option
                            // </NavMenu> */}
                            //     <NavMenu to='/delivery/city-area'>
                            //         City / Area
                            //     </NavMenu>
                            //     <NavMenu to='/delivery/city-under-delivery'>
                            //         City Under Delivery
                            //     </NavMenu>
                            // </SubMenu>
                        }
                        {/* /delivery manage */}

                        {/* home content manage */}
                        {
                            permissions.includes("home_content_manage") &&
                            <SubMenu
                                title={"Home Content Manage"}
                                icon={<RiHome8Line />}
                            >
                                {/* <NavMenu to='/home-content/layout'>
                                    Layout Manage
                                </NavMenu> */}
                                <NavMenu to='/home-content/hero'>
                                    Hero
                                </NavMenu>
                                <NavMenu to='/home-content/slider'>
                                    Slider Manage
                                </NavMenu>
                                {/* <NavMenu to='/home-content/seo'>
                                    Home SEO
                                </NavMenu> */}
                            </SubMenu>
                        }
                        {/* /home content manage */}

                        {/* report */}
                        {/* <SubMenu
                            title={"Report"}
                            icon={<GoReport />}
                        >
                            <NavMenu to='/report/customers'>
                                Customers Purchase
                            </NavMenu>
                            <NavMenu to='/report/products'>
                                Product Sales QTY
                            </NavMenu>
                            <NavMenu to='/report/sales'>
                                Sales
                            </NavMenu>
                        </SubMenu> */}
                        {/* /report */}

                        {/* reward points */}
                        {/* {
                            permissions.includes("point_manage") &&
                            <SubMenu
                                title={"Point manage"}
                                icon={<FaCoins />}
                            >
                                <NavMenu to='/reward/customers'>
                                    Customers Points
                                </NavMenu>
                                <NavMenu to='/reward/setting'>
                                    Reward Point Setting
                                </NavMenu>
                            </SubMenu>
                        } */}
                        {/* /reward points */}

                        {/* campaign page */}
                        {/* {
                            permissions.includes("campaign") &&
                            <SubMenu
                                title={"Campaign"}
                                icon={<MdOutlineCampaign />}
                            >
                                <NavMenu to='/campaign/coupon'>
                                    Coupon Code
                                </NavMenu>
                                <NavMenu to='/campaign/offer'>
                                    Offer
                                </NavMenu>
                            </SubMenu>
                        } */}
                        {/* /campaign page */}

                        {/* specification page */}
                        {
                            // permissions.includes("specifications") &&
                            // <SubMenu
                            //     title={"Specifications"}
                            //     icon={<GrServers />}
                            // >
                            //     <NavMenu to='/specs/new'>
                            //         Add New
                            //     </NavMenu>
                            //     <NavMenu to='/specs'>
                            //         List
                            //     </NavMenu>
                            // </SubMenu>
                        }
                        {/* specification page */}
                        {/* career page */}
                        {/* {
                            permissions.includes("career") &&
                            <SubMenu
                                title={"Career Page"}
                                icon={<MdPeopleAlt />}
                            >
                                <NavMenu to='/career'>
                                    Main Page
                                </NavMenu>
                                <NavMenu to='/career/new'>
                                    Add New Job
                                </NavMenu>
                                <NavMenu to='/career/list'>
                                    All Jobs
                                </NavMenu>
                                <NavMenu to='/career/applicant-list'>
                                    All Applicants
                                </NavMenu>
                            </SubMenu>
                        } */}
                        {/* /career page */}

                        {/* admin manage page */}
                        {/* {
                            permissions.includes("admin_manage") &&
                            <SubMenu
                                title={"Admin Manage"}
                                icon={<RiAdminLine />}
                            >
                                <NavMenu to='/admin-manage/new'>
                                    Add New
                                </NavMenu>
                                <NavMenu to='/admin-manage/list'>
                                    Admin List
                                </NavMenu>
                            </SubMenu>
                        } */}
                        {/* /admin manage page */}

                        {
                            permissions.includes("media") &&
                            <NavMenu to='/media' icon={<FaImages />} >
                                Media
                            </NavMenu>
                        }

                        {/* setting page */}
                        {
                            permissions.includes("setting") &&
                            <SubMenu
                                title={"Setting"}
                                icon={<MdSettings />}
                            >
                                <NavMenu to='/setting'>
                                    Site Setting
                                </NavMenu>
                                <NavMenu to='/setting/shop-address'>
                                    Shop Address
                                </NavMenu>
                                {/* <NavMenu to='/setting/emi'>
                                    EMI
                                </NavMenu> */}
                                <NavMenu to='/setting/footer'>
                                    Footer
                                </NavMenu>
                            </SubMenu>
                        }
                        {/* /setting page */}
                    </Menu>
                </SidebarContent>

                <SidebarFooter className='center-inner py-2'>
                    <button className={clsx('h-10 w-10 text-xl center-inner hover:text-primary hover:text-2xl duration-300', collapsedSidebar && 'rotate-180')} onClick={() => setCollapsedSidebar(!collapsedSidebar)}><IoIosArrowBack /></button>
                    <div className='w-full py-2 hidden md:flex items-center justify-between px-4 sticky top-0 z-20'>
                        <div>
                            <button type="button" className='text-white text-2xl md:hidden' onClick={() => setToggleSidebar(true)}>
                                <AiOutlineMenu />
                            </button>
                        </div>
                        <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
                            {/* <Avatar src="https://joeschmoe.io/api/v1/random" className='border border-white' size={'large'}>{me.data?.firstname}</Avatar> */}
                            <p className='cursor-pointer rounded border border-primary text-white px-1 py-1'>{me.data?.firstname}</p>
                        </Dropdown>
                    </div>
                </SidebarFooter>
            </ProSidebar>
            <main className='h-full w-full overflow-auto bg-card-4'>
                <div className='w-full py-2 bg-secondary flex items-center justify-between px-4 sticky top-0 z-20 md:hidden'>
                    <div>
                        <button type="button" className='text-white text-2xl md:hidden' onClick={() => setToggleSidebar(true)}>
                            <AiOutlineMenu />
                        </button>
                    </div>
                    <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
                        {/* <Avatar src="https://joeschmoe.io/api/v1/random" className='border border-white' size={'large'}>{me.data?.firstname}</Avatar> */}
                        <p className='cursor-pointer rounded border border-primary text-white px-1 py-1'>{me.data?.firstname}</p>
                    </Dropdown>
                </div>
                <Suspense fallback={<Loader />}>
                    <Outlet />
                </Suspense>
            </main>
        </div>
    )
}

export default Layout