import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Http from "../helpers/http";
import { type PreOrder } from './../models/order';
import type Pagination from './../models/pagination';

type PreOrderState = {
    preOrderList: {
        loading: boolean,
        data: Array<PreOrder>,
        error?: string | null,
        meta?: Pagination,
    }
}

const initialState: PreOrderState = {
    preOrderList: {
        loading: false,
        data: []
    }
};

const preOrderSlice = createSlice({
    name: "pre-order",
    initialState,
    reducers: {
        fetchPreOrderListRequest: (state) => {
            state.preOrderList.loading = true;
        },
        fetchPreOrderListSuccess: (state, action) => {
            state.preOrderList = {
                loading: false,
                data: action.payload.data,
                error: null,
                meta: action.payload.meta
            }
        },
        fetchPreOrderListFailure: (state, action) => {
            state.preOrderList.loading = false;
            state.preOrderList.error = action.payload;
        },
        removePreOrderItem: (state, action) => {
            state.preOrderList.data = state.preOrderList.data.filter(order => order.id !== action.payload);
        }
    }
});

export default preOrderSlice.reducer;
export const preOrderActions = preOrderSlice.actions;

export const fetchPreOrderListAction = (pageNo: number) => async (dispatch: any) => {
    dispatch(preOrderActions.fetchPreOrderListRequest());
    try {
        const response = await Http.Get({ path: `pre-orders?page=${pageNo}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Order fetching failed");
        }
        dispatch(preOrderActions.fetchPreOrderListSuccess({
            data: result.data,
            meta: result.meta
        }));

    } catch (error: any) {
        dispatch(preOrderActions.fetchPreOrderListFailure(error.message));
    }
}

export const deletePreOrderAction = (orderId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `pre-orders/${orderId}`, useAuth: true });
        if (!response.ok) {
            throw new Error("Failed");
        }
        dispatch(preOrderActions.removePreOrderItem(orderId));

    } catch (error: any) {
        toast.error(error.message);
    }
}