import { createSlice } from "@reduxjs/toolkit";
import Http from './../helpers/http';
import { type RecentOrder, type MonthlySold } from './../models/statistics';

type StatisticsState = {
    recentOrders: {
        loading: boolean,
        data: RecentOrder[],
        error?: string
    },
    monthlySold: {
        loading: boolean,
        data: MonthlySold[],
        error?: string
    }
};

const initialState: StatisticsState = {
    recentOrders: {
        loading: false,
        data: []
    },
    monthlySold: {
        loading: false,
        data: []
    }
};

const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        //recent orders -> start
        fetchRecentOrdersRequest: (state) => {
            state.recentOrders.loading = true;
        },
        fetchRecentOrdersSuccess: (state, action) => {
            state.recentOrders = {
                loading: false,
                data: action.payload
            };
        },
        fetchRecentOrdersFailure: (state, action) => {
            state.recentOrders.loading = false;
            state.recentOrders.error = action.payload;
        },
        //recent orders -> end

        //monthly sold -> start
        fetchMonthlySoldRequest: (state) => {
            state.monthlySold.loading = true;
        },
        fetchMonthlySoldSuccess: (state, action) => {
            state.monthlySold = {
                loading: false,
                data: action.payload
            };
        },
        fetchMonthlySoldFailure: (state, action) => {
            state.monthlySold.loading = false;
            state.monthlySold.error = action.payload;
        }
        //monthly sold -> end
    }
});

export default statisticsSlice.reducer;
export const statisticsActions = statisticsSlice.actions;

export const fetchRecentOrdersAction = ({ cache, period }: { cache: boolean, period: number }) => async (dispatch: any) => {
    dispatch(statisticsActions.fetchRecentOrdersRequest());
    try {
        const res = await Http.Get({
            path: `reports/orders?cache=${cache ? '1' : '0'}&period=${period}`,
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Failed");
        }
        dispatch(statisticsActions.fetchRecentOrdersSuccess(result.report));
    } catch (error: any) {
        dispatch(statisticsActions.fetchRecentOrdersFailure(error.message));
    }
};

export const fetchMonthlySoldAction = ({ cache }: { cache: boolean }) => async (dispatch: any) => {
    dispatch(statisticsActions.fetchMonthlySoldRequest());
    try {
        const res = await Http.Get({
            path: `reports/orders/monthly?cache=${cache ? '1' : '0'}`,
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Failed");
        }

        dispatch(statisticsActions.fetchMonthlySoldSuccess(result.report));
    } catch (error: any) {
        dispatch(statisticsActions.fetchMonthlySoldFailure(error.message));
    }
};