import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { type Media } from "../models/media";
import type Pagination from './../models/pagination';
import { type NewProduct } from './../models/product';
import Http from "./../helpers/http";

type MultipleLangData = {
    en: string
};

type AttributeData = {
    name: MultipleLangData,
    description: MultipleLangData
};

export type Product = {
    id: number;
    product_type_id: number;
    status: string;
    attribute_data: AttributeData;
    brand: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    variants_count: number;
    media: Media[]
};

type ProductState = {
    productList: {
        loading: boolean,
        data: Product[],
        error?: string | null,
        meta?: Pagination
    },
    createProduct: {
        loading: boolean,
        newlyCreated?: number | null
    }
}

const initialState: ProductState = {
    productList: {
        loading: false,
        data: []
    },
    createProduct: {
        loading: false
    }
}

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        fetchProductsRequest: (state) => {
            state.productList.loading = true;
        },
        fetchProductsSuccess: (state, action) => {
            state.productList = {
                loading: false,
                data: action.payload.data,
                error: null,
                meta: action.payload.meta
            }
        },
        fetchProductsFailure: (state, action) => {
            state.productList.loading = false;
            state.productList.error = action.payload;
        },
        //PRODUCT DELETE
        deleteProductSuccess: (state, action) => {
            state.productList.data = state.productList.data.filter((prod) => prod.id !== action.payload);
        },
        //PRODUCT CREATE
        createProductRequest: (state) => {
            state.createProduct.loading = true;
        },
        createProductSuccess: (state, action) => {
            state.createProduct = {
                loading: false,
                newlyCreated: action.payload.id
            };
        },
        createProductFailure: (state) => {
            state.createProduct = {
                loading: false,
                newlyCreated: null
            }
        },
        clearNewlyCreated: (state) => {
            state.createProduct.newlyCreated = undefined;
        }
    }
});

export const productActions = productSlice.actions;
export default productSlice.reducer;

//ACTIONS
export const fetchProductsAction = (page = 1,pageSize=20) => async (dispatch: any) => {
    dispatch(productActions.fetchProductsRequest());
    try {
        const response = await Http.Get({ path: `products?page=${page}&limit=${pageSize}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Product fetching failed");
        }
        dispatch(productActions.fetchProductsSuccess({
            data: result.data,
            meta: result.meta
        }));

    } catch (error: any) {
        dispatch(productActions.fetchProductsFailure(error.message));
    }

};

export const deleteProductAction = (productId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `products/${productId}`, useAuth: true });
        if (!response.ok) {
            throw new Error("Something wrong");
        }
        toast.success("Deleted");
        dispatch(productActions.deleteProductSuccess(productId));

    } catch (error: any) {
        toast.error(error.message);
    }
};

export const createProductAction = (data: NewProduct) => async (dispatch: any) => {
    dispatch(productActions.createProductRequest());
    try {
        const response = await Http.Post({ path: `products`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message ? JSON.stringify(result.message) : "Fail to create product");

        }
        dispatch(productActions.createProductSuccess(result));

         console.log(result);


    } catch (error: any) {
        dispatch(productActions.createProductFailure());
        try {
            const errors = Object.entries(JSON.parse(error.message));
            errors.forEach((err: any) =>
                toast.error(err[1][0])
            );

        } catch (e) {
            toast.error(error.message);
        }
    }
}

export const searchProductAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `search/products`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Product fetching failed");
        }

        dispatch(productActions.fetchProductsSuccess({
            data: result,
            meta: undefined
        }));

    } catch (error: any) {
        throw error;
    }
}