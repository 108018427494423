import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { type Staff } from "../models/staff";
import Http from '../helpers/http';

type StaffState = {
    staffList: {
        loading: boolean,
        data: Staff[],
        error?: string
    }
};

const initialState: StaffState = {
    staffList: {
        loading: false,
        data: []
    }
};

const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        fetchStaffListRequest: (state) => {
            state.staffList.loading = true;
        },
        fetchStaffListSuccess: (state, action) => {
            state.staffList = {
                loading: false,
                data: action.payload
            };
        },
        fetchStaffListFailure: (state, action) => {
            state.staffList.loading = false;
            state.staffList.error = action.payload;
        },
        updateStaff: (state, action) => {
            state.staffList.data = state.staffList.data.map(staff => staff.id === action.payload.id ? action.payload : staff);
        },
        removeStaff: (state, action) => {
            state.staffList.data = state.staffList.data.filter(staff => staff.id !== action.payload);
        }
    }
});

export default staffSlice.reducer;
export const staffActions = staffSlice.actions;

export const fetchStaffListAction = () => async (dispatch: any) => {
    dispatch(staffActions.fetchStaffListRequest());
    try {
        const res = await Http.Get({
            path: 'staffs',
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Failed");
        }

        dispatch(staffActions.fetchStaffListSuccess(result));

    } catch (error: any) {
        dispatch(staffActions.fetchStaffListFailure(error.message));
    }
};

export const updateStaffPermissionsAction = (data: any, staffId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `staffs/permissions/${staffId}`,
            data,
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Failed");
        }

        dispatch(staffActions.updateStaff(result));
        toast.success("Updated");

    } catch (error: any) {
        toast.error(error.message);
    }
};

export const deleteStaffAction = (staffId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Delete({
            path: `staffs/${staffId}`,
            useAuth: true
        });

        if (!res.ok) {
            throw new Error("Failed");
        }

        dispatch(staffActions.removeStaff(staffId));
        toast.success("Deleted");

    } catch (error: any) {
        toast.error(error.message);
    }
}