import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import BasicLayout from "../components/BasicLayout";

//PAGES
const DashboardPage = React.lazy(() => import("./../pages/DashboardPage"));
const MediaPage = React.lazy(() => import("./../pages/MediaPage"));
const MenuManagePage = React.lazy(() => import("../pages/MenuManagePage"));
const NotFoundPage = React.lazy(() => import("../pages/NotFoundPage"));

//order pages
const OrderNewPage = React.lazy(() => import("./../pages/order/OrderNewPage"));
const OrderListPage = React.lazy(
  () => import("./../pages/order/OrderListPage")
);
const OrderDetailsPage = React.lazy(
  () => import("../pages/order/OrderDetailsPage")
);

//pre order pages
const PreOrderListPage = React.lazy(
  () => import("../pages/preOrder/PreOrderListPage")
);
const PreOrderDetailsPage = React.lazy(
  () => import("../pages/preOrder/PreOrderDetailsPage")
);

//product pages
const ProductListPage = React.lazy(
  () => import("../pages/product/ProductListPage")
);
const NewProductPage = React.lazy(
  () => import("../pages/product/NewProductPage")
);
const EditProductPage = React.lazy(
  () => import("../pages/product/EditProductPage")
);

//category pages
const EditCategoryPage = React.lazy(
  () => import("../pages/category/EditCategoryPage")
);
const ManageCategoryPage = React.lazy(
  () => import("../pages/category/ManageCategoryPage")
);

//attribute pages
const AttributeManage = React.lazy(() => import("./../pages/attribute"));
const AttributeEditPage = React.lazy(
  () => import("../pages/attribute/AttributeEditPage")
);

//delivery manage pages
const DeliveryTypePage = React.lazy(
  () => import("../pages/delivery/DeliveryTypePage")
);
const DeliveryOptionPage = React.lazy(
  () => import("../pages/delivery/DeliveryOptionPage")
);
const DeliveryCityAreaPage = React.lazy(
  () => import("../pages/delivery/DeliveryCityAreaPage")
);
const CityUnderDelivery = React.lazy(
  () => import("../pages/delivery/CityUnderDelivery")
);


//home content manage pages
const HomeSliderManagePage = React.lazy(() => import("../pages/homeContent/HomeSliderManagePage"));
const HomeLayoutManagePage = React.lazy(() => import("../pages/homeContent/HomeLayoutManagePage"));
const HomeContentEditPage = React.lazy(() => import("../pages/homeContent/HomeContentEditPage"));
const AddNewHomeContentPage = React.lazy(() => import("../pages/homeContent/AddNewHomeContentPage"));
const HomeSliderEditPage = React.lazy(() => import("../pages/homeContent/HomeSliderEditPage"));
const HomeSeo = React.lazy(() => import("../pages/homeContent/HomeSeo"));
const HomeHero = React.lazy(() => import("../pages/homeContent/HomeHero"));
const HomeSlider = React.lazy(() => import("../pages/homeContent/HomeSlider"));

//report pages
const CustomerPurchasePage = React.lazy(
  () => import("../pages/report/CustomerPurchasePage")
);
const ProductSalesQtyPage = React.lazy(
  () => import("../pages/report/ProductSalesQtyPage")
);
const SalesPage = React.lazy(() => import("../pages/report/SalesPage"));


//setting page
const SiteSettingPage = React.lazy(
  () => import("../pages/setting/SiteSettingPage")
);
const ShopAddressPage = React.lazy(
  () => import("../pages/setting/ShopAddressPage")
);
const EmiPage = React.lazy(() => import("../pages/setting/EmiPage"));
const FooterSettingPage = React.lazy(
  () => import("../pages/setting/FooterSettingPage")
);

//custom page manage
const CustomPageListPage = React.lazy(
  () => import("../pages/customPage/CustomPageListPage")
);
const CustomPageEditPage = React.lazy(
  () => import("../pages/customPage/CustomPageEditPage")
);
const CustomPageNewPage = React.lazy(
  () => import("../pages/customPage/CustomPageNewPage")
);

//campaign page
const CampCouponCodePage = React.lazy(
  () => import("../pages/campaign/CampCouponCodePage")
);
const CampOfferManagePage = React.lazy(
  () => import("../pages/campaign/CampOfferManagePage")
);
const CreateOfferPage = React.lazy(
  () => import("../pages/campaign/CreateOfferPage")
);
const EditOfferPage = React.lazy(
  () => import("../pages/campaign/EditOfferPage")
);

//search terms
const AddNewSearchTerm = React.lazy(
  () => import("../pages/searchTerms/AddNewSearchTermPage")
);
const EditSearchTermPage = React.lazy(
  () => import("../pages/searchTerms/EditSearchTermPage")
);
const SearchTermsListPage = React.lazy(
  () => import("../pages/searchTerms/SearchTermsListPage")
);

//authentication
const LoginPage = React.lazy(() => import("../pages/auth/LoginPage"));
const RegistrationPage = React.lazy(
  () => import("../pages/auth/RegistrationPage")
);

//admin
const AdminListPage = React.lazy(() => import("../pages/admin/AdminListPage"));
const EditAdminPage = React.lazy(() => import("../pages/admin/EditAdminPage"));

//specifications
const SpecListPage = React.lazy(() => import("../pages/specs/SpecListPage"));
const AddNewSpecPage = React.lazy(
  () => import("../pages/specs/AddNewSpecPage")
);
const EditSpecPage = React.lazy(() => import("../pages/specs/EditSpecPage"));

//customers
const CustomerListPage = React.lazy(
  () => import("../pages/customer/CustomerListPage")
);
const CustomerDetailsPage = React.lazy(
  () => import("../pages/customer/CustomerDetailsPage")
);

//Reviews page 
const ReviewsIndex = React.lazy(() => import("../pages/Reviews/ReviewsIndex"));
const ReviewsPending = React.lazy(() => import("../pages/Reviews/ReviewsPending"));

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<DashboardPage />} />


        <Route path="order">
          {/* <Route path="new" element={<OrderNewPage />} /> */}
          <Route path=":orderId" element={<OrderDetailsPage />} />
          {/* <Route path="pre" element={<PreOrderListPage />} /> */}
          {/* <Route path="pre/:preOrderId" element={<PreOrderDetailsPage />} /> */}
          <Route index element={<OrderListPage />} />
        </Route>

        <Route path="category">
          <Route path=":categoryId" element={<EditCategoryPage />} />
          <Route index element={<ManageCategoryPage />} />
        </Route>

        <Route path="media" element={<MediaPage />} />

        <Route path="reviews">
          <Route index element={<ReviewsIndex />} />
          <Route path="pending-reviews" element={<ReviewsPending />} />
        </Route>

        <Route path="attributes">
          <Route path=":attrId" element={<AttributeEditPage />} />
          <Route index element={<AttributeManage />} />
        </Route>

        <Route path="product">
          <Route path="new" element={<NewProductPage />} />
          <Route path=":productId" element={<EditProductPage />} />
          <Route index element={<ProductListPage />} />
        </Route>

        <Route path="customer">
          <Route path=":customerId" element={<CustomerDetailsPage />} />
          <Route index element={<CustomerListPage />} />
        </Route>

        <Route path="terms">
          <Route index element={<SearchTermsListPage />} />
          <Route path="new" element={<AddNewSearchTerm />} />
          <Route path="edit/:termId" element={<EditSearchTermPage />} />
        </Route>

        <Route path="menu" element={<MenuManagePage />} />

        {/* <Route path="delivery">
          <Route path="type" element={<DeliveryTypePage />} />
          <Route path="option" element={<DeliveryOptionPage />} />
          <Route path="city-area" element={<DeliveryCityAreaPage />} />
          <Route path="city-under-delivery" element={<CityUnderDelivery />} />
        </Route> */}

        <Route path="custom-page">
          <Route index element={<CustomPageListPage />} />
          <Route path="new" element={<CustomPageNewPage />} />
          <Route path=":pageId" element={<CustomPageEditPage />} />
        </Route>

        <Route path="home-content">
          {/* <Route path="slider" element={<HomeSliderManagePage />} /> */}
          <Route path="slider/:sliderId" element={<HomeSliderEditPage />} />
          <Route path="layout" element={<HomeLayoutManagePage />} />
          <Route path="layout/new" element={<AddNewHomeContentPage />} />
          <Route path="layout/:contentId" element={<HomeContentEditPage />} />
          <Route path="seo" element={<HomeSeo />} />
          <Route path="hero" element={<HomeHero />} />
          <Route path="slider" element={<HomeSlider />} />
        </Route>

        <Route path="report">
          <Route path="customers" element={<CustomerPurchasePage />} />
          <Route path="products" element={<ProductSalesQtyPage />} />
          <Route path="sales" element={<SalesPage />} />
        </Route>

        <Route path="campaign">
          <Route path="coupon" element={<CampCouponCodePage />} />
          <Route path="offer">
            <Route path="new" element={<CreateOfferPage />} />
            <Route path="edit/:offerId" element={<EditOfferPage />} />
            <Route index element={<CampOfferManagePage />} />
          </Route>
        </Route>

        <Route path="admin-manage">
          <Route path="new" element={<RegistrationPage />} />
          <Route path="list" element={<AdminListPage />} />
          <Route path="list/:staffId" element={<EditAdminPage />} />
        </Route>

        <Route path="setting">
          <Route index element={<SiteSettingPage />} />
          <Route path="shop-address" element={<ShopAddressPage />} />
          <Route path="emi" element={<EmiPage />} />
          <Route path="footer" element={<FooterSettingPage />} />
        </Route>

        <Route path="specs">
          <Route index element={<SpecListPage />} />
          <Route path="new" element={<AddNewSpecPage />} />
          <Route path="edit/:specId" element={<EditSpecPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppNavigator;
