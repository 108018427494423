import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import errorExtractor from "../utils/errorExtractor";
import Http from './../helpers/http';
import { type DeliveryOption, type City, type CityDetails, type Division } from './../models/delivery';

type DeliveryState = {
    deliveryOptions: {
        loading: boolean,
        data: DeliveryOption[],
        error?: string | null
    },
    divisionList: {
        loading: boolean,
        data: Division[],
        error?: any
    },
    cityList: {
        loading: boolean,
        data: City[],
        error?: any
    },
    cityDetails: {
        loading: boolean,
        data?: CityDetails,
        error?: any
    }
};

const initialState: DeliveryState = {
    deliveryOptions: {
        loading: false,
        data: []
    },
    divisionList: {
        loading: false,
        data: []
    },
    cityList: {
        loading: false,
        data: []
    },
    cityDetails: {
        loading: false
    }
};

const deliverySlice = createSlice({
    name: "delivery",
    initialState,
    reducers: {
        fetchDeliveryOptionsRequest: (state) => {
            state.deliveryOptions.loading = true;
        },
        fetchDeliveryOptionsSuccess: (state, action) => {
            state.deliveryOptions = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        fetchDeliveryOptionsFailure: (state, action) => {
            state.deliveryOptions.loading = false;
            state.deliveryOptions.error = action.payload;
        },
        addDeliveryOptionSuccess: (state, action) => {
            if (state.deliveryOptions.data.length) {
                state.deliveryOptions.data.push(action.payload);
            }
        },
        updateDeliveryOptionSuccess: (state, action) => {
            state.deliveryOptions.data = state.deliveryOptions.data.map(dOption => dOption.id === action.payload.id ? action.payload : dOption);
        },
        deleteDeliveryOptionSuccess: (state, action) => {
            state.deliveryOptions.data = state.deliveryOptions.data.filter(dOption => dOption.id !== action.payload);
        },

        //division
        fetchDivisionListSuccess: (state, action) => {
            state.divisionList.data = action.payload;
        },

        //city
        fetchCityListRequest: (state) => {
            state.cityList = {
                ...state.cityList,
                loading: true,
                error: null
            }
        },
        fetchCityListSuccess: (state, action) => {
            state.cityList = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        fetchCityListFailure: (state, action) => {
            state.cityList = {
                ...state.cityList,
                loading: false,
                error: action.payload
            }
        },
        fetchCityDetailsRequest: (state) => {
            state.cityDetails.loading = true;
        },
        fetchCityDetailsSuccess: (state, action) => {
            state.cityDetails = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        fetchCityDetailsFailure: (state, action) => {
            state.cityDetails.loading = false;
            state.cityDetails.error = action.payload;
        },
        updateCitySuccess: (state, action) => {
            state.cityList.data = state.cityList.data.map(city => city.id === action.payload.id ? action.payload : city);
        },
        addNewCity: (state, action) => {
            if (state.cityList.data.length) {
                state.cityList.data.push(action.payload);
            }
        },
        removeCity: (state, action) => {
            state.cityList.data = state.cityList.data.filter(city => city.id !== action.payload);
        }
    }
});

export default deliverySlice.reducer;

export const deliveryActions = deliverySlice.actions;


//ACTIONS
export const fetchDeliveryOptionsAction = () => async (dispatch: any) => {
    dispatch(deliveryActions.fetchDeliveryOptionsRequest());
    try {
        const response = await Http.Get({ path: `delivery-options`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Delivery option fetch failed");
        }
        dispatch(deliveryActions.fetchDeliveryOptionsSuccess(result));

    } catch (error: any) {
        dispatch(deliveryActions.fetchDeliveryOptionsFailure(error.message));
    }
}
//add new delivery type
export const addNewDeliveryOptionAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `delivery-options`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Added");
        dispatch(deliveryActions.addDeliveryOptionSuccess(result));
    } catch (error: any) {
        toast.error(error.message);
    }
}

//update delivery option
export const updateDeliveryOptionAction = (deliveryId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `delivery-options/${deliveryId}`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Updated");
        dispatch(deliveryActions.updateDeliveryOptionSuccess(result));

    } catch (error: any) {
        toast.error(error.message);
    }
}
//delete delivery option
export const deleteDeliveryOptionAction = (deliveryId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `delivery-options/${deliveryId}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            if (typeof (result.message) === "string") {
                throw new Error(result.message)
            } else {
                throw new Error(errorExtractor(result.message)[0]);
            }
        }
        toast.success("Deleted");
        dispatch(deliveryActions.deleteDeliveryOptionSuccess(deliveryId));

    } catch (error: any) {
        toast.error(error.message);
    }
}
//division list
export const fetchDivisionList = () => async (dispatch: any) => {
    try {
        const response = await Http.Get({ path: `shipping/divisions`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        dispatch(deliveryActions.fetchDivisionListSuccess(result.data));
    } catch (error) {

    }
}

//city list
export const fetchCityListAction = () => async (dispatch: any) => {
    dispatch(deliveryActions.fetchCityListRequest());
    try {
        const response = await Http.Get({ path: `shipping/cities`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        dispatch(deliveryActions.fetchCityListSuccess(result.data));

    } catch (error: any) {
        dispatch(deliveryActions.fetchCityListFailure(error.message));
    }
}

//city details
export const fetchCityDetailsAction = (cityId: number) => async (dispatch: any) => {
    dispatch(deliveryActions.fetchCityDetailsRequest());
    try {
        const response = await Http.Get({ path: `shipping/cities/${cityId}`, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        dispatch(deliveryActions.fetchCityDetailsSuccess(result));
    } catch (error: any) {
        dispatch(deliveryActions.fetchCityDetailsFailure(error.message));
    }
}

//city update
export const updateCityAction = (cityId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `shipping/cities/${cityId}`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Updated");
        dispatch(deliveryActions.updateCitySuccess(result));


    } catch (error: any) {
        toast.error(error.message);
    }
}
//CREATE NEW CITY
export const createCityAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `shipping/cities`, data, useAuth: true });
        const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Created");
        dispatch(deliveryActions.addNewCity(result));
    } catch (error: any) {
        toast.error(error.message);
    }
}
//DELETE CITY
export const deleteCityAction = (cityId: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `shipping/cities/${cityId}`, useAuth: true });
        // const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Deleted");
        dispatch(deliveryActions.removeCity(cityId));
    } catch (error: any) {
        toast.error(error.message);
    }
}

// ---------------------AREA----------------------
//update area
export const updateAreaAction = (areaId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Put({ path: `shipping/areas/${areaId}`, data, useAuth: true });
        // const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Updated");


    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};
//add new area
export const createAreaAction = (data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `shipping/areas`, data, useAuth: true });
        // const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Created");
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
};
//add new area
export const deleteAreaAction = (id: number) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `shipping/areas/${id}`, useAuth: true });
        // const result = await response.json();
        if (!response.ok) {
            throw new Error("Failed");
        }
        toast.success("Deleted");
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}

//CITY UNDER DELIVERY
export const addCityDeliveryOptionAction = (cityId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Post({ path: `shipping/cities/${cityId}/shipping-options`, data, useAuth: true });
        if (!response.ok) {
            throw new Error("Failed");
        }

    } catch (error) {
        throw error;
    }
}

export const deleteCityDeliveryOptionAction = (cityId: number, data: any) => async (dispatch: any) => {
    try {
        const response = await Http.Delete({ path: `shipping/cities/${cityId}/shipping-options`, useAuth: true });
        if (!response.ok) {
            throw new Error("Failed");
        }

    } catch (error) {
        throw error;
    }
}