import { createSlice } from "@reduxjs/toolkit";
import Http from './../helpers/http';
import { type Spec } from "../models/spec";
import { toast } from "react-toastify";
import type PaginationModel from "../models/pagination";

type SpecState = {
    specList: {
        loading: boolean,
        data: Spec[],
        error?: string,
        pagination?: PaginationModel
    }
};

const initialState: SpecState = {
    specList: {
        loading: false,
        data: []
    }
};

const specSlice = createSlice({
    name: "spec",
    initialState,
    reducers: {
        fetchSpecsRequest: (state) => {
            state.specList.loading = true;
        },
        fetchSpecsSuccess: (state, action) => {
            state.specList = {
                loading: false,
                data: action.payload.data,
                pagination: action.payload.meta
            }
        },
        fetchSpecsFailure: (state, action) => {
            state.specList.loading = false;
            state.specList.error = action.payload;
        },
        addNewSpec: (state, action) => {
            if (state.specList.data.length) {
                state.specList.data.push(action.payload);
            }
        },
        updateSpec: (state, action) => {
            state.specList.data = state.specList.data.map(spec => spec.id === action.payload.id ? action.payload : spec);
        }
    }
});

export default specSlice.reducer;
export const specActions = specSlice.actions;

export const fetchSpecsAction = (pageNo?: number) => async (dispatch: any) => {
    dispatch(specActions.fetchSpecsRequest());
    try {
        const res = await Http.Get({
            path: `specifications?paginate=1${pageNo ? `&page=${pageNo}` : ''}`,
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Fail");
        }
        dispatch(specActions.fetchSpecsSuccess(result));
    } catch (error: any) {
        dispatch(specActions.fetchSpecsFailure(error.message));
    }
};

export const createSpecAction = (data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Post({
            path: `specifications`,
            data,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Fail to create new spec");
        }
        toast.success("New spec added");
    } catch (error: any) {
        toast.error(error.message);
    }
}

export const specUpdateAction = (id: number, data: any) => async (dispatch: any) => {
    try {
        const res = await Http.Put({
            path: `specifications/${id}`,
            data,
            useAuth: true
        });
        const result = await res.json();
        if (!res.ok) {
            throw new Error("Fail");
        }
        toast.success("Updated");
        dispatch(specActions.updateSpec(result));
    } catch (error: any) {
        toast.error(error.message);
    }
};

export const deleteSpecAction = (specId: number) => async (dispatch: any) => {
    try {
        const res = await Http.Delete({
            path: `specifications/${specId}`,
            useAuth: true
        });
        if (!res.ok) {
            throw new Error("Fail");
        }
        toast.success("Deleted");
    } catch (error: any) {
        toast.error(error.message);
        throw error;
    }
}